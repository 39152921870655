<!-- This template is used for dropdown navbar -->
<template>
  <div>
    <div class="py-1 mt-4 ml-4">
      <div class="flex justify-between items-center text-gray-300 font-normal cursor-pointer" @click="toggleShow">
        {{ title }}
        <svg fill="#fff" class="h-4 w-3 mr-3" :class="{'rotate-90' : showMenu}"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 407.436 407.436" xml:space="preserve">
      <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
      </svg>
      </div>
      <div class="overflow-hidden dropdown-menu font-normal" :class="this.class">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<!-- Scripts -->
<script>
export default {
  name: "SmallNavSide",
  data: () => ({
    showMenu: false
  }),
  props: {
    title: {
      type: String,
      default: 'Menu',
    },
    height: {
      type: String,
      default: 'full',
    },
  },
  methods: {
    toggleShow() {
      this.showMenu = !this.showMenu;
    }
  },
  computed: {
    class() {
      return this.showMenu ? this.height : "h-0";
    }
  }
}
</script>

<!-- Styles -->
<style>
.dropdown-menu {
  transition: 560ms;
}
svg.rotate-90 {
  transform: rotate(90deg);
}
</style>
