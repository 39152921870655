import axios from 'axios'
export const api = {
    // url: process.env.VUE_APP_BACKEND_BASE_URL,
    // url: "https://backend.tanwir.onwork.uz",
    url: "https://backend.tanwir.uz",
};

const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}