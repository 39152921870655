<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="isOpen ? 'block' : 'hidden'"
      @click="isOpen = false"
      class="fixed inset-0 z-50 transition-opacity bg-black opacity-50"
    ></div>
    <!-- End Backdrop -->

    <div
      :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
      class="fixed left-0 z-[100] w-72 overflow-y-auto transition duration-300 transform bg-gray-900 translate-x-0 inset-0"
    >
      <div class="flex items-center justify-center mt-8">
        <div class="flex items-center">
          <img class="w-[200px]" src="@/assets/images/logo_white.png" alt="" />
        </div>
      </div>

      <nav class="mt-10">
        <p
          v-show="personal.user_roles_id === 1"
          class="pl-4 text-xs font-semibold mb-4 text-gray-400"
        >
          Umumiy
        </p>
        <router-link
          @click="isOpen = false"
          v-show="personal.user_roles_id === 1 || personal.user_roles_id === 19"
          class="flex items-center px-2 py-2 mt-4 duration-200 border-l-4"
          :class="[$route.name === 'Dashboard' ? activeClass : inactiveClass]"
          to="/dashboard"
        >
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            ></path>
          </svg>

          <span class="mx-4">Dashboard</span>
        </router-link>
        <p class="pl-4 my-2 text-xs font-semibold mb-4 text-gray-400">Modullar</p>
        <NavSide
          :title="packages_control"
          :height="[
            dropdownHeight ? 'h-[850px]' : dropdownHeightNITS ? 'h-[380px]' : 'h-64',
          ]"
          v-show="
            personal.user_roles_id === 1 ||
            personal.user_roles_id === 19 ||
            personal.user_roles_id === 3
          "
        >
          <router-link
            @click="isOpen = false"
            v-show="
              personal.user_roles_id === 1 ||
              personal.user_roles_id === 19 ||
              personal.user_roles_id === 3
            "
            class="my-image flex items-center px-2 py-2 mt-4 duration-200 border-l-4"
            :class="[$route.name === 'MyPackages' ? activeClass : inactiveClass]"
            to="/package-groups"
          >
            <img
              :class="[$route.name === 'MyPackages' ? 'opacity-100' : 'opacity-50']"
              src="@/assets/icons/document.svg"
              alt=""
              class="w-6 h-6"
            />
            <span class="mx-4">Tur paket guruhlari</span>
          </router-link>
          <router-link
            @click="isOpen = false"
            v-show="
              personal.user_roles_id === 1 ||
              personal.user_roles_id === 19 ||
              personal.user_roles_id != 3
            "
            class="my-image flex items-center px-2 py-2 mt-4 duration-200 border-l-4"
            :class="[$route.name === 'PackageGroups' ? activeClass : inactiveClass]"
            to="/packages"
          >
            <img
              :class="[$route.name === 'PackageGroups' ? 'opacity-100' : 'opacity-50']"
              src="@/assets/icons/document.svg"
              alt=""
              class="w-6 h-6"
            />
            <span class="mx-4">Tur paket qo'shish</span>
          </router-link>
          <router-link
            @click="isOpen = false"
            v-show="
              personal.user_roles_id === 1 ||
              personal.user_roles_id === 19 ||
              personal.user_roles_id != 3
            "
            class="my-image flex items-center px-2 py-2 mt-4 duration-200 border-l-4"
            :class="[$route.name === 'priceCharges' ? activeClass : inactiveClass]"
            to="/priceCharges"
          >
            <img
              :class="[$route.name === 'priceCharges' ? 'opacity-100' : 'opacity-50']"
              src="@/assets/icons/document.svg"
              alt=""
              class="w-6 h-6"
            />
            <span class="mx-4">Narx o'zgarishi</span>
          </router-link>
          <SmallNavSide
            :title="tour_package"
            v-show="personal.user_roles_id === 1 || personal.user_roles_id === 19"
            @click="dropdownHeight = true"
            class="z-10"
            :height="[
              dropdownHeight ? 'h-[800px]' : dropdownHeight ? 'h-[800px]' : 'h-64',
            ]"
          >
            <router-link
              v-for="item in tourPackageModal"
              :key="item"
              @click="
                isOpen = false;
                dropdownHeightA = false;
              "
              v-show="personal.user_roles_id === 1 || personal.user_roles_id === 19"
              class="my-image flex items-center px-2 py-2 mt-4 duration-200 border-l-4"
              :class="[$route.name === item.route_name ? activeClass : inactiveClass]"
              :to="item.url"
            >
              <img
                :class="[$route.name === item.route_name ? 'opacity-100' : 'opacity-50']"
                :src="item.icon"
                alt=""
                class="w-6 h-6"
              />

              <span class="mx-4">{{ item.name }} </span>
            </router-link>
          </SmallNavSide>
          <SmallNavSide
            :title="tour_package_accounting"
            v-show="personal.user_roles_id === 1 || personal.user_roles_id === 19"
            @click="dropdownHeightNITS = !dropdownHeightNITS"
            class="z-10"
          >
            <router-link
              v-for="item in tourPackageAccounting"
              :key="item"
              @click="
                isOpen = false;
                dropdownHeightNITS = false;
              "
              v-show="personal.user_roles_id === 1 || personal.user_roles_id === 19"
              class="my-image flex items-center px-2 py-2 mt-4 duration-200 border-l-4"
              :class="[$route.name === item.route_name ? activeClass : inactiveClass]"
              :to="item.url"
            >
              <img
                :class="[$route.name === item.route_name ? 'opacity-100' : 'opacity-50']"
                :src="item.icon"
                alt=""
                class="w-6 h-6"
              />

              <span class="mx-4">{{ item.name }} </span>
            </router-link>
          </SmallNavSide>
        </NavSide>
        <NavSide
          :title="option"
          :height="personal.user_roles_id === 5 ? 'h-14' : 'h-[450px]'"
          v-show="
            personal.user_roles_id === 2 ||
            personal.user_roles_id === 1 ||
            personal.user_roles_id === 19 ||
            personal.user_roles_id === 5
          "
        >
          <router-link
            v-for="item in optionModal"
            :key="item"
            @click="isOpen = false"
            v-show="
              personal.user_roles_id === 2 ||
              personal.user_roles_id === 1 ||
              personal.user_roles_id === 19 ||
              personal.user_roles_id === 5
            "
            class="my-image flex items-center px-2 py-2 mt-4 duration-200 border-l-4"
            :class="[$route.name === item.route_name ? activeClass : inactiveClass]"
            :to="item.url"
          >
            <img
              :class="[$route.name === item.route_name ? 'opacity-100' : 'opacity-50']"
              :src="item.icon"
              alt=""
              class="w-6 h-6"
            />
            <span class="mx-4">{{ item.name }}</span>
          </router-link>
        </NavSide>

        <NavSide
          :title="hotel"
          :height="'h-[330px]'"
          v-show="
            personal.user_roles_id === 4 ||
            personal.user_roles_id === 1 ||
            personal.user_roles_id === 19
          "
        >
          <router-link
            v-for="item in hotelModal"
            :key="item"
            @click="isOpen = false"
            v-show="
              personal.user_roles_id === 4 ||
              personal.user_roles_id === 1 ||
              personal.user_roles_id === 19
            "
            class="my-image flex items-center px-2 py-2 mt-4 duration-200 border-l-4"
            :class="[$route.name === item.route_name ? activeClass : inactiveClass]"
            :to="item.url"
          >
            <img
              :class="[$route.name === item.route_name ? 'opacity-100' : 'opacity-50']"
              :src="item.icon"
              alt=""
              class="w-6 h-6"
            />
            <span class="mx-4">{{ item.name }}</span>
          </router-link>
        </NavSide>

        <NavSide
          :title="user_control"
          :height="'h-56'"
          v-show="personal.user_roles_id === 1 || personal.user_roles_id === 19"
        >
          <router-link
            v-for="item in userControlModal"
            :key="item"
            @click="isOpen = false"
            v-show="personal.user_roles_id === 1 || personal.user_roles_id === 19"
            class="my-image flex items-center px-2 py-2 mt-4 duration-200 border-l-4"
            :class="[$route.name === item.route_name ? activeClass : inactiveClass]"
            :to="item.url"
          >
            <img
              :class="[$route.name === item.route_name ? 'opacity-100' : 'opacity-50']"
              :src="item.icon"
              alt=""
              class="w-6 h-6"
            />
            <span class="mx-4">{{ item.name }}</span>
          </router-link>
        </NavSide>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useSidebar } from "../hooks/useSidebar";
import SmallNavSide from "@/components/SmallNavSide";

const { isOpen } = useSidebar();
const activeClass = ref("bg-gray-600 bg-opacity-25 text-gray-100 border-gray-100");
const inactiveClass = ref(
  "border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100"
);
</script>

<script>
import axios from "axios";
import { api } from "@/config";
import NavSide from "./NavSide.vue";
import router from "@/router";
import user_icon from "@/assets/icons/user.svg";
import home_icon from "@/assets/icons/home.svg";
import comment_icon from "@/assets/icons/comment.svg";
import folder_icon from "@/assets/icons/folder.svg";

export default {
  data: () => ({
    tourPackageModal: [
      {
        name: "Xizmat turi",
        route_name: "ServiceType",
        url: "/servicetype",
        icon: folder_icon,
      },
      {
        name: "Xizmatlar",
        route_name: "Services",
        url: "/services",
        icon: folder_icon,
      },
      {
        name: "Tur xizmatlari",
        route_name: "tourservice",
        url: "/tourservice",
        icon: folder_icon,
      },
      {
        name: "Xizmat tafsilotlari",
        route_name: "servicedetails",
        url: "/service-details",
        icon: folder_icon,
      },
      {
        name: "Xizmat tafsilotlarining maydonlari",
        route_name: "servicedetailfields",
        url: "/service-detail-fields",
        icon: folder_icon,
      },
      {
        name: "Xizmat tafsilotlari maydonlarining guruhlari",
        route_name: "servicedetailfieldgroups",
        url: "/service-detail-field-groups",
        icon: folder_icon,
      },
      {
        name: "Foydalanuvchi statistikasi",
        route_name: "userstatistics",
        url: "/user-statistics",
        icon: folder_icon,
      },
      {
        name: "Paket statistikasi",
        route_name: "packagesstatistics",
        url: "/packages-statistics",
        icon: folder_icon,
      },
      {
        name: "Paket safar kunlari",
        route_name: "packageschedules",
        url: "/package-schedules",
        icon: folder_icon,
      },
    ],
    optionModal: [
      {
        name: "Yangiliklar",
        route_name: "news",
        url: "/news",
        icon: comment_icon,
      },
      {
        name: "Umra",
        route_name: "newsUmrahRituals",
        url: "/newsUmrahRituals",
        icon: comment_icon,
      },
      {
        name: "Namoz o'qish tartibi",
        route_name: "newsPrayingRules",
        url: "/newsPrayingRules",
        icon: comment_icon,
      },
      {
        name: "Duolar",
        route_name: "addprayers",
        url: "/addprayers",
        icon: comment_icon,
      },
      {
        name: "Jo'natilgan so'rovlar",
        route_name: "requestview",
        url: "/requestview",
        icon: comment_icon,
      },
      {
        name: "SOS xabar",
        route_name: "sos-signal",
        url: "/sos-signal",
        icon: comment_icon,
      },
      {
        name: "Kontrakt shablonlari",
        route_name: "contract-template",
        url: "/contract-template",
        icon: comment_icon,
      },
      {
        name: "SMS xizmati",
        route_name: "get-notifications",
        url: "/get-notifications",
        icon: comment_icon,
      },
    ],
    userControlModal: [
      {
        name: "So'rovlar",
        route_name: "subagent-requests",
        url: "/subagent-requests",
        icon: user_icon,
      },
      {
        name: "Subagentlar",
        route_name: "subagents",
        url: "/subagents",
        icon: user_icon,
      },
      {
        name: "Foydalanuvchilar",
        route_name: "Users",
        url: "/users",
        icon: user_icon,
      },
      {
        name: "Lavozimlar",
        route_name: "Roles",
        url: "/roles",
        icon: user_icon,
      },
    ],
    hotelModal: [
      {
        name: "Xonalar",
        route_name: "rooms",
        url: "/rooms",
        icon: home_icon,
      },
      {
        name: "Xonadagi qulayliklar",
        route_name: "roomfeatures",
        url: "/roomfeatures",
        icon: home_icon,
      },
      {
        name: "Qulayliklar",
        route_name: "features",
        url: "/features",
        icon: home_icon,
      },
      {
        name: "Narxlar",
        route_name: "prices",
        url: "/prices",
        icon: home_icon,
      },
      {
        name: "Xona buyurtma qilish",
        route_name: "roomorders",
        url: "/roomorders",
        icon: home_icon,
      },
      {
        name: "Buyurtmachilar",
        route_name: "room-order-items",
        url: "/room-order-items",
        icon: home_icon,
      },
    ],
    tourPackageAccounting: [
      {
        name: "Hisobotlar",
        route_name: "OrdersAc",
        url: "/orders-accounting",
        icon: home_icon,
      },
    ],
    dropdownHeight: false,
    dropdownHeightA: false,
    dropdownHeightNITS: false,
    personal: [],
    hotel: "Mehmonxona buyurtmasi",
    option: "Qo'shimcha imkoniyatlar",
    tour_package: "Tur Paketlar bo'yicha",
    tour_package_accounting: "NITS bo'yicha hisobot",
    user_control: "Foydalanuvchi boshqarish",
    packages_control: "Paketlar boshqaruvi",
  }),
  components: {
    NavSide,
  },
  methods: {
    init() {
      axios
        .get(api.url + "/gen/profile", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.personal = response.data;
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status === 401) {
            router.push("/");
          }
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.my-image:hover img {
  opacity: 100;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #8d8d8d;
}
</style>
