import { createApp } from "vue";
import VueApexCharts from "vue3-apexcharts";
import DashboardLayout from "./components/DashboardLayout.vue";
import EmptyLayout from "./components/EmptyLayout.vue";
import "./assets/tailwind.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import App from "./App.vue";
import router from "./router";
import Toaster from "@meforma/vue-toaster";

const app = createApp(App);
app.component("default-layout", DashboardLayout);
app.component("empty-layout", EmptyLayout);

app
  .use(ElementPlus)
  .use(router)
  .use(VueApexCharts)
  .use(CKEditor)
  .use(Toaster, { position: "top-right" })
  .mount("#app");
